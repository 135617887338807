import React from "react";
import Truck from "react-feather/dist/icons/truck";
import Calendar from "react-feather/dist/icons/calendar";

class CancelWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      remaining_time: props.remaining_time - 30 // Shave 30 seconds off to account for any latency.
    };

    this._decrementCounter = this._decrementCounter.bind(this);
  }

  componentDidMount() {
    setInterval(this._decrementCounter, 1000);
  }

  _decrementCounter() {
    const { remaining_time } = this.state;
    this.setState({ remaining_time: remaining_time - 1 });
  }

  _formatTimeString(number) {
    const minutes = Math.floor(number / 60);
    const seconds = number - minutes * 60;

    let timeString;
    if (minutes > 0) {
      timeString = `${minutes}m ${seconds}s`;
    } else {
      timeString = `${seconds}s`;
    }

    return timeString;
  }

  render() {
    if (remaining_time <= 0) return null;

    const token = document.querySelector("meta[name=csrf-token]").content;
    const { remaining_time } = this.state;
    const { path, seed_file, send_at, widget_copy } = this.props;
    const send_at_key = send_at ? "scheduled" : "asap";
    const recipient_source_key = seed_file ? "seed_file" : "default";
    const Icon = send_at ? Calendar : Truck;

    return (
      <div className="mb-6 border-jade-100 card bg-jade-50">
        <h2 className="flex items-center gap-2 text-lg font-bold text-jade-900">
          <span className="p-3 rounded-full bg-jade-200">
            <Icon size={18} strokeWidth={2} className="w-5 h-5" />
          </span>
          <div>
            {widget_copy[send_at_key].title}
            {send_at && <div className="text-xs">{send_at}</div>}
          </div>
        </h2>
        <CountdownMessage
          suffix={widget_copy[send_at_key][recipient_source_key].description}
          remaining_time={this._formatTimeString(remaining_time)}
        />
        <div className="flex gap-4 mt-4">
          <form action={path} method="POST">
            <input type="hidden" name="_method" value="DELETE" />
            <input type="hidden" name="authenticity_token" value={token} />
            <button className="bg-white border-jade-100 slm-button secondary" type="submit">
              Cancel Send
            </button>
          </form>
          <form action={path} method="POST">
            <input type="hidden" name="_method" value="PATCH" />
            <input type="hidden" name="authenticity_token" value={token} />
            <input type="hidden" id="overridden_at" name="override" value="1" />
            <button className="slm-button" type="submit">
              {widget_copy[send_at_key].confirm_button}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const CountdownMessage = ({ remaining_time, suffix }) => {
  return (
    <div className="text-jade-900">
      In <strong>{remaining_time}</strong> {suffix}
    </div>
  );
};

export default CancelWidget;
