import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "errorBanner"];

  connect() {
    this.fields = Array.from(this.formTarget.elements).filter((f) => f.tagName === "SELECT");

    this.formTarget.noValidate = true;
    this.formTarget.addEventListener("submit", this.submitHandler.bind(this), true);
  }

  submitHandler(e) {
    if(e.submitter.name === "save_and_close") { return true; }

    this.fields.forEach(this.toggleErrorClass);

    this.toggleErrorBanner();

    if (this.showErrorBanner){
      e.preventDefault();
      e.stopPropagation();
    }
  }

  inputValidityHandler(e) {
    const input = e.target;

    this.toggleErrorClass(input);

    if (this.showErrorBanner) {
      this.toggleErrorBanner();
    }
  }

  toggleErrorClass(input) {
    input.closest("tr").classList.toggle("has-error", !input.checkValidity());
  }

  toggleErrorBanner() {
    this.showErrorBanner = !this.formTarget.checkValidity();

    this.errorBannerTarget.classList.toggle("hidden", !this.showErrorBanner);
  }
}
